<template>
<div>
  <PageHeader title="Exporteren" />
  <div class="tw-my-5 tw-mx-2.5 tw-p-8 tw-bg-white tw-rounded tw-shadow-lg">
    <form class="tw-max-w-lg">
      <p>Hier kan je contacten exporteren aan de hand van verschillende criteria</p>
      <div class="form-group">
        <label class="control-label tw-mr-2">Kantoor</label>
        <button v-if="exportableOffices" class="tw-underline tw-mt-4" type="button" @click="toggleAllOffices()">{{ message }}</button>
        <div>
          <select class="form-control" v-model="filter.office" :disabled="allOfficesSelected">
            <option v-for="office in exportableOffices" :value="office" :key="office.id">{{ office.display_name }}</option>
          </select>
        </div>
      </div>
      <div v-if="allOfficesSelected" class="tw-my-4">
        <span class="font-bold mr-4" v-for="(office, index) in exportableOffices" :key="office.id">
          {{ office.display_name }}
          <span v-if="index !== exportableOffices.length - 1"> | </span>
        </span>
      </div>
      <div class="form-group">
        <label><input type="checkbox" v-model="filter.owners"> Eigenaars</label>
        <template v-if="filter.owners">
          <label class="radio"><input type="radio" value="all" v-model="filter.owners_subtype"> Alle eigenaars</label>
          <label class="radio"><input type="radio" value="rent" v-model="filter.owners_subtype"> Eigenaars die
            verhuren</label>
          <label class="radio"><input type="radio" value="sell" v-model="filter.owners_subtype"> Eigenaars die
            verkopen</label>
        </template>
      </div>
      <div class="form-group">
        <label><input type="checkbox" v-model="filter.buyers"> Kopers</label>
      </div>
      <div class="form-group">
        <label><input type="checkbox" v-model="filter.renters"> Huurders</label>
      </div>
      <div class="form-group">
        <label><input type="checkbox" v-model="filter.candidates_rent"> Kandidaten verhuur</label>
      </div>
      <div class="form-group">
        <label><input type="checkbox" v-model="filter.candidates_resale"> Kandidaten herverkoop</label>
      </div>
      <div class="form-group">
        <label><input type="checkbox" v-model="filter.candidates_new"> Kandidaten nieuwbouw</label>
      </div>
      <button type="button" class="btn btn-success" @click="handleContactExport(filter)" :disabled="!enableExport">
        Exporteren
      </button>
    </form>
  </div>
</div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import { errorModal, successModal, startLoadingModal } from '@/modalMessages'
import { exportContactFilter } from '@/services/contacts'
import { getOffices } from '@/services/organization'

export default {
  name: 'ContactExport',
  components: {
    PageHeader
  },
  data: function () {
    return {
      allOfficesSelected: false,
      previousSelectedOffice: null,
      exportableOffices: [],
      filter: {
        office: null,
        owners: false,
        owners_subtype: 'all',
        buyers: false,
        renters: false,
        candidates_rent: false,
        candidates_resale: false,
        candidates_new: false
      }
    }
  },
  computed: {
    enableExport () {
      const filter = this.filter
      return (filter.office || this.allOfficesSelected) && (filter.owners || filter.buyers || filter.renters || filter.candidates_rent || filter.candidates_resale || filter.candidates_new)
    },
    message () {
      return this.allOfficesSelected ? 'Alle beschikbare kantoren zijn geselecteerd: klik om ongedaan te maken' : 'Alle beschikbare kantoren selecteren'
    }
  },
  created () {
    this.fetchOffices()
  },
  methods: {
    ...mapActions('contacts', ['pollContactExportStatus']),

    async fetchOffices () {
      const response = await getOffices({ params: { dropdown: 1 } })
      this.exportableOffices = response.data?.results
      return response
    },
    async handleContactExport (data) {
      startLoadingModal('Bezig met exporteren van contacten.')
      try {
        const exportParameters = { ...data }
        if (this.allOfficesSelected) {
          exportParameters.office_ids = this.exportableOffices.map(item => item.id)
        } else {
          exportParameters.office_ids = [exportParameters.office.id]
        }
        delete exportParameters.office
        const response = await exportContactFilter(exportParameters)
        const jobId = response.data.job_id
        await this.pollContactExportStatus(jobId)
        successModal('De contacten zijn geexporteerd.')
      } catch (e) {
        errorModal('Kan contacten niet exporteren, gelieve opnieuw te proberen')
      }
    },

    toggleAllOffices () {
      this.allOfficesSelected = !this.allOfficesSelected
      if (this.allOfficesSelected) {
        this.previousSelectedOffice = this.filter.office
        this.filter.office = null
      } else {
        this.filter.office = this.previousSelectedOffice
      }
    }
  }
}
</script>

<style scoped>
  .radio {
    display: block;
    position: relative;
    left: 40px;
  }

</style>
